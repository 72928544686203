import api from '@/service/api'

const dataOnly = (payload) => {
  return payload?.data?.data ?? null
}

const fetchMenuApi = async () => {
  return api.get('/api/info/menu').then(dataOnly)
}

const fetchProgramMenuApi = async (params) => {
  return api
    .get('/api/info/menu', {
      params
    })
    .then(dataOnly)
}

const fetchBlockListApi = async (id) => {
  return api
    .get('/api/info/block/list', {
      params: { id }
    })
    .then(dataOnly)
}
const fetchBlockApi = async (id) => {
  return api
    .get('/api/info/block', {
      params: { id }
    })
    .then(dataOnly)
}
const fetchNewsApi = async (id) => {
  return api
    .get('/api/info/news', {
      params: { id }
    })
    .then(dataOnly)
}

const fetchVideoListApi = async (params) => {
  return api
    .get('/api/info/video/list', {
      params
    })
    .then(dataOnly)
}

const fetchVideoApi = async (id) => {
  return api
    .get('/api/info/video', {
      params: { id }
    })
    .then(dataOnly)
}
const fetchGalleryApi = async (id) => {
  return api
    .get('/api/info/image-gallery', {
      params: { id }
    })
    .then(dataOnly)
}
const fetchEditorApi = async (id) => {
  return api
    .get('/api/info/editor', {
      params: { id }
    })
    .then(dataOnly)
}
const fetchWebsiteListApi = async (params) => {
  return api
    .get('/api/info/website/list', {
      params: {
        ...params,
        sort: 'position,1'
      }
    })
    .then(dataOnly)
}
const fetchWebsiteApi = async (id) => {
  return api
    .get('/api/info/website', {
      params: { id }
    })
    .then(dataOnly)
}
const fetchCategoryListApi = async (params) => {
  return api
    .get('/api/info/category/list', {
      params
    })
    .then(dataOnly)
}

const searchApi = async (params) => {
  return api
    .get('/api/info/search', {
      params
    })
    .then(dataOnly)
}

const fetchListByTagApi = async (params) => {
  return api
    .get('/api/info/list-by-tags', {
      params
    })
    .then(dataOnly)
}
const fetchListByLinksApi = async (tags, page) => {
  return api
    .get('/api/info/list-by-links', {
      params: { tags, page }
    })
    .then(dataOnly)
}
const fetchListLatestApi = async (params) => {
  return api
    .get('/api/info/news/list', {
      params
    })
    .then(dataOnly)
}
const fetchListApi = async (params) => {
  return api
    .get('/api/info/list', {
      params
    })
    .then(dataOnly)
}
const fetchCategoryApi = async (id) => {
  return api
    .get('/api/info/category', {
      params: { id }
    })
    .then(dataOnly)
}
const fetchSeeMoreApi = async (id, limit) => {
  return api
    .get('/api/info/partial/see-more', {
      params: { id, limit }
    })
    .then(dataOnly)
}

const fetchSeeMoreApiWebsite = async (params) => {
  return api
    .get('/api/info/partial/website/block', {
      params
    })
    .then(dataOnly)
}

const fetchTopFiveProgramsApi = async (params) => {
  return api
    .get('/api/info/partial/top5', {
      params
    })
    .then(dataOnly)
}

const fetchProgramsApi = async () => {
  return api.get('/api/info/block/top-program-tv').then(dataOnly)
}

const fetchMiniPlayerApi = async (id = 45478734 ) => {
  return api
  .get('/api/info/block/mini-player', {
    params: { id }
  })
  .then(dataOnly)
  // return api.get('/api/info/block/mini-player').then(dataOnly)
}

const fetchPoliticiansApi = async () => {
  return api.get('/api/info/politicians').then(dataOnly)
}

const fetchPoliticiansDetailsApi = async (id) => {
  return api
    .get('/api/info/politicians/detail', {
      params: { id }
    })
    .then(dataOnly)
}

const fetchPoliticiansMembersTabsApi = (id) => {
  return api
    .get('/api/info/politicians/members-tabs', {
      params: { id }
    })
    .then(dataOnly)
}

const fetchPoliticiansMembersListApi = (params) => {
  return api
    .get('/api/info/politicians/members-list', {
      params
    })
    .then(dataOnly)
}

const fetchProgramsListApi = async (station_code, date, include_images) => {
  return api
    .get('/api/info/program-tv/occurrences', {
      params: { station_code, date, include_images }
    })
    .then(dataOnly)
}

const fetchWeatherApi = async (object_id, view, forecast_type, timestamp) => {
  return api
    .get('https://www.tvp.pl/shared/weather_meteopl.php', {
      params: { object_id, view, forecast_type, timestamp }
    })
    .then(dataOnly)
}
const fetchProgramDetailsApi = async (id) => {
  return api
    .get('/api/info/program-tv/program', {
      params: { id }
    })
    .then(dataOnly)
}

const fetchWeatherNewsApi = async (id, device) => {
  return api
    .get('/api/info/block/list', {
      params: { id, device }
    })
    .then(dataOnly)
}

const fetchProgramInstanceDetailsApi = async (id) => {
  return api
    .get('/api/info/program-tv/occurrence', {
      params: { id }
    })
    .then(dataOnly)
}

const fetchInstanceListProgramApi = async (program_id, occurrence_ids_not) => {
  return api
    .get('/api/info/program-tv/program/occurrences', {
      params: {
        program_id,
        occurrence_ids_not
      }
    })
    .then(dataOnly)
}

const fetchNextProgramApi = async (id) => {
  return api
    .get('/api/info/program-tv/occurrence/next', {
      params: {
        id
      }
    })
    .then(dataOnly)
}

const fetchPrevProgramApi = async (id) => {
  return api
    .get('/api/info/program-tv/occurrence/prev', {
      params: {
        id
      }
    })
    .then(dataOnly)
}

const fetchAdsApi = async (id) => {
  return api
    .get('/api/info/ads/get', {
      params: {
        id
      }
    })
    .then(dataOnly)
}

const fetchMetaSeo = (params) => {
  return api
    .get('/api/info/meta', {
      params
    })
    .then(dataOnly)
}

const fetchFormDetailsApi = (id) => {
  return api
    .get('/api/info/form', {
      params: {
        id
      }
    })
    .then(dataOnly)
}

const fetchGemiusApi = (id) => {
  return api
    .get('/api/info/gemius', {
      params: {
        id
      }
    })
    .then(dataOnly)
}

const fetchGemiusApiByPath = (url) => {
  return api
    .get('/api/info/gemius', {
      params: {
        url
      }
    })
    .then(dataOnly)
}

const fetchLogosFooterApi = async () => {
  return api.get('/api/info/footer/logotypes').then(dataOnly)
}

const fetchUrlsFooterApi = async () => {
  return api.get('/api/info/footer/links').then(dataOnly)
}

const fetchUrgentBeamApi = async () => {
  return api.get('/api/info/banner').then(dataOnly)
}

export {
  fetchMenuApi,
  fetchBlockListApi,
  fetchBlockApi,
  fetchNewsApi,
  fetchVideoApi,
  fetchGalleryApi,
  fetchEditorApi,
  fetchWebsiteListApi,
  fetchWebsiteApi,
  fetchCategoryListApi,
  searchApi,
  fetchListByTagApi,
  fetchListByLinksApi,
  fetchListLatestApi,
  fetchListApi,
  fetchCategoryApi,
  fetchSeeMoreApi,
  fetchVideoListApi,
  fetchProgramsApi,
  fetchMiniPlayerApi,
  fetchPoliticiansApi,
  fetchPoliticiansDetailsApi,
  fetchProgramsListApi,
  fetchWeatherApi,
  fetchWeatherNewsApi,
  fetchProgramDetailsApi,
  fetchProgramInstanceDetailsApi,
  fetchInstanceListProgramApi,
  fetchNextProgramApi,
  fetchPrevProgramApi,
  fetchAdsApi,
  fetchMetaSeo,
  fetchPoliticiansMembersTabsApi,
  fetchPoliticiansMembersListApi,
  fetchFormDetailsApi,
  fetchGemiusApi,
  fetchGemiusApiByPath,
  fetchTopFiveProgramsApi,
  fetchSeeMoreApiWebsite,
  fetchProgramMenuApi,
  fetchLogosFooterApi,
  fetchUrlsFooterApi,
  fetchUrgentBeamApi
}
