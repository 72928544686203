import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import {
  fetchBlockApi,
  fetchBlockListApi,
  fetchGalleryApi,
  fetchListApi,
  fetchNewsApi,
  fetchCategoryApi,
  fetchVideoApi,
  fetchCategoryListApi,
  searchApi,
  fetchVideoListApi,
  fetchSeeMoreApi,
  fetchListLatestApi,
  fetchWebsiteListApi,
  fetchWebsiteApi,
  fetchListByTagApi,
  fetchEditorApi,
  fetchGemiusApi,
  fetchGemiusApiByPath,
  fetchUrgentBeamApi
} from '@/service'
import { usePagination } from '@/composables/usePagination'

export const useSectionsStore = defineStore('sections', () => {
  const blockPage = ref({})
  const blockDetails = ref({})
  const newsDetails = ref({})
  const galleryDetails = ref({})
  const videoDetails = ref({})
  const videoList = ref({})
  const categoryList = ref({})
  const category = ref({})
  const searchItems = ref([])
  const totalCountSearch = ref(0)
  const seeMoreItems = ref([])
  const totalCountSeeMore = ref(0)
  const latest = ref({})
  const website = ref({})
  const websiteList = ref({})
  const tagsList = ref(null)
  const editorDetails = ref({})
  const tagsBoxList = ref([])
  const urgentItem = ref({})

  const categoryItemsList = ref({})
  // const tag = ref({})
  // const tagItems = ref({})

  const itemsBlockPage = computed(() => blockPage.value)
  const itemsNewsDetails = computed(() => newsDetails.value)
  const itemsGalleryDetails = computed(() => galleryDetails.value)
  const itemsVideoDetails = computed(() => videoDetails.value)
  const categoryDetails = computed(() => category.value)
  const categoryListDetails = computed(() => categoryList.value)
  const itemsEditorDetails = computed(() => editorDetails.value)
  const pageSettings = ref({
    id: null,
    type: null,
    website_id: null
  })
  const historyPageSettings = ref({})

  const gemiusDetails = ref({})
  const itemsGemiusDetails = computed(() => gemiusDetails.value)

  const gemiusDetailsByPath = ref({})
  const itemsGemiusDetailsByPath = computed(() => gemiusDetailsByPath.value)

  const { fetchPagination } = usePagination()

  const addHistoryPage = (path) => {
    historyPageSettings.value[path] = { ...pageSettings.value, ...{ path: path } }
    const session = JSON.parse(sessionStorage.getItem('historyPage')) || {}
    session[path] = { ...pageSettings.value, ...{ path: path } }
    sessionStorage.setItem('historyPage', JSON.stringify(session))
  }
  const pageSettingType = computed(() => pageSettings.value?.type ?? null)
  const pageSettingId = computed(() => pageSettings.value?.id ?? null)
  const pageSettingWebsiteId = computed(() => pageSettings.value?.website_id ?? null)

  const initPage = (payload) => {
    pageSettings.value = payload
  }

  const routingDataPath = (paylaod) => {
    if (paylaod) {
      return `/${paylaod.join('/')}`
    }
    return '/'
  }

  const fetchBlockListByPage = async (id) => {
    return fetchBlockListApi(id).then((response) => {
      blockPage.value = { ...blockPage.value, ...{ [id || 'SG']: response } }
      return Promise.resolve(response)
    })
  }

  const fetchBlockDetails = async (id) => {
    return fetchBlockApi(id).then((response) => {
      blockDetails.value = { ...blockDetails.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchTagsBoxes = async (params) => {
    return fetchListByTagApi (params).then((response) => {
      tagsBoxList.value = response.items
      return Promise.resolve(response)
    })
  }

  const fetchNewsDetails = async (id) => {
    return fetchNewsApi(id).then((response) => {
      newsDetails.value = { ...newsDetails.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchGalleryDetails = async (id) => {
    return fetchGalleryApi(id).then((response) => {
      galleryDetails.value = { ...galleryDetails.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchVideoDetails = async (id) => {
    return fetchVideoApi(id).then((response) => {
      videoDetails.value = { ...videoDetails.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchVideoListOld = async (id, direct, editorId) => {
    return fetchVideoListApi(id, direct, editorId).then((response) => {
      videoList.value = { ...videoList.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchVideoList = async (params) => {
    return fetchPagination({
      params,
      items: videoList,
      fetch: fetchVideoListApi
    })
  }

  const fetchEditorDetails = async (id) => {
    return fetchEditorApi(id).then((response) => {
      editorDetails.value = { ...editorDetails.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchList = async (params) => {
    return fetchPagination({
      params,
      items: categoryList,
      fetch: fetchListApi
    })
  }

  const fetchTags = async (params) => {
    return fetchPagination({
      params,
      items: tagsList,
      fetch: fetchListByTagApi
    })
  }

  const fetchCategory = async (id, editorId, page, limit) => {
    return fetchCategoryApi(id, editorId, page, limit).then((response) => {
      category.value = { ...category.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchCategoryList = async (params) => {
    return fetchPagination({
      params,
      items: categoryItemsList,
      fetch: fetchCategoryListApi
    })
  }

  const fetchListLatest = async (params) => {
    return fetchPagination({
      params,
      items: latest,
      fetch: fetchListLatestApi
    })
  }

  const fetchWebsiteList = async (params) => {
    return fetchPagination({
      params,
      items: websiteList,
      fetch: fetchWebsiteListApi
    })
  }

  const fetchWebsite = async (id) => {
    return fetchWebsiteApi(id).then((response) => {
      website.value = { [id]: response }
      return Promise.resolve(response)
    })
  }

  const fetchSearch = async (params, cleanable) => {
    return fetchPagination(
      {
        params,
        items: searchItems,
        fetch: searchApi
      },
      cleanable
    )
  }

  const fetchSeeMore = async (id, limit) => {
    return fetchSeeMoreApi(id, limit).then((response) => {
      seeMoreItems.value = response?.items ?? []
      totalCountSeeMore.value = response?.total_count ?? 0
      return Promise.resolve(response)
    })
  }

  const fetchGemiusInfo = async (id) => {
    return fetchGemiusApi(id).then((response) => {
      gemiusDetails.value = { ...gemiusDetails.value, ...{ [id]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchGemiusInfoByPath = async (url) => {
    return fetchGemiusApiByPath(url).then((response) => {
      gemiusDetailsByPath.value = { ...gemiusDetailsByPath.value, ...{ [url]: response } }
      return Promise.resolve(response)
    })
  }

  const fetchUrgentBeam = async () => {
    return fetchUrgentBeamApi().then((response) => {
      urgentItem.value = response
      return Promise.resolve(response)
    })
  }

  return {
    categoryList,
    category,
    pageSettings,
    pageSettingType,
    historyPageSettings,
    blockPage,
    itemsBlockPage,
    blockDetails,
    itemsNewsDetails,
    itemsGalleryDetails,
    itemsVideoDetails,
    categoryListDetails,
    categoryDetails,
    videoList,
    videoDetails,
    categoryItemsList,
    searchItems,
    totalCountSearch,
    latest,
    seeMoreItems,
    totalCountSeeMore,
    website,
    websiteList,
    tagsList,
    itemsGemiusDetails,
    itemsGemiusDetailsByPath,
    pageSettingId,
    fetchBlockListByPage,
    fetchBlockDetails,
    fetchNewsDetails,
    fetchGalleryDetails,
    fetchVideoDetails,
    fetchList,
    fetchCategory,
    initPage,
    routingDataPath,
    addHistoryPage,
    fetchCategoryList,
    fetchSearch,
    fetchSeeMore,
    fetchListLatest,
    fetchWebsiteList,
    fetchWebsite,
    fetchVideoList,
    fetchTags,
    fetchEditorDetails,
    itemsEditorDetails,
    fetchGemiusInfo,
    fetchGemiusInfoByPath,
    pageSettingWebsiteId,
    fetchTagsBoxes,
    tagsBoxList,
    fetchUrgentBeam,
    urgentItem
  }
})
