import axios from 'axios'
const BASE_URL = encodeURI(window.__apiHost)
const service = axios.create({
    baseURL: BASE_URL,
    headers: {}, //
    params: {
      device: 'www'
    }
})
service.interceptors.response.use(
    response => response,
    error => {
        console.warn('Axios endpoint error: ', error)
    }
);
export default service