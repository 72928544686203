import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import Popper from 'vue3-popper'
import { createHead } from '@unhead/vue'

import App from './App.vue'
import router from './router'
import './assets/scss/index.scss'
import 'vue-skeletor/dist/vue-skeletor.css'
import { Skeletor } from 'vue-skeletor'

// import { adsConfig } from './ads/ads';
// adsConfig.init();

import vSelect from 'vue-select'
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: () => h('span', { class: 'search-icon' })
  }
})

const app = createApp(App)
const head = createHead()
app.component(Skeletor.name, Skeletor)
app.provide('$anthill', anthill)
app.use(createPinia())
app.component('v-select', vSelect)
app.component('v-popper', Popper)
app.use(router)
app.use(head)
app.config.errorHandler = function (err, vm, info) {
  console.log('Error: ', err, info, vm)
}
app.mount('#app')
