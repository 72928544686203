import { createRouter, createWebHistory } from 'vue-router'
import { useSectionsStore } from '@/stores/section'
import { storeToRefs } from 'pinia'

const GeneratePage = () => import('@/views/GeneratePage.vue')
const DynamicPage = () => import('@/views/DynamicPage.vue')
const SearchPage = () => import('@/views/SearchPage.vue')
const ErrorPage = () => import('@/views/404.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/components/layout/DefaultLayout.vue'),
      children: [
        { path: '/', component: GeneratePage },
        {
          path: '/:id/:slug',
          component: DynamicPage
        },
        {
          path: '/:slug',
          component: DynamicPage
        },
        { path: '/program-tv', component: () => import('@/views/ProgramTvPage.vue') },
        {
          path: '/program-tv/:programName/:id',
          component: () => import('@/views/ProgramTvDetailPage.vue')
        },
        { path: '/program-tv', component: () => import('./views/ProgramTvPage.vue') },
        { path: '/programy-tv-detal', component: () => import('./views/ProgramTvDetailPage.vue') },
        { path: '/pogoda', component: () => import('./views/WeatherPage.vue') },
        { path: '/aplikacja', component: () => import('./views/AppLp.vue') },
        // { path: '/wiadomosci', component: () => import('./views/programs/ProgramHomePage.vue') },
        {
          path: '/szukaj',
          component: SearchPage
        },
        {
          path: '/404',
          component: ErrorPage
        }
      ]
    },
    {
      path: '/:id/:slug/webview',
      component: () => import('@/views/ArticleWebviewPage.vue')
    }
    // {
    //   path: '/:id/iframe',
    //   component: () => import('@/views/elections/ElectionsIframePage.vue')
    // }
  ],
  scrollBehavior(to) {
    if (!to.hash) {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const sectionsStore = useSectionsStore()
  const { pageSettingType, historyPageSettings } = storeToRefs(sectionsStore)
  const { initPage, addHistoryPage } = sectionsStore
  if (!pageSettingType.value) {
    initPage(window.__pageSettings)
    if (window.__pageSettings.type === '404') {
      next('/404')
    }
  }
  const historyPath = historyPageSettings.value[to.path] ?? undefined
  const sessions = JSON.parse(sessionStorage.getItem('historyPage')) || {}
  const sessionsPath = sessions[to.path] ?? undefined

  if (from.path === '/' || (window.event?.type == 'popstate' ?? false)) {
    if (historyPath) {
      initPage(historyPath)
    } else {
      if (sessionsPath) {
        initPage(sessionsPath)
      }
    }
  }
  addHistoryPage(to.path)
  next()
})


//integracja potrzebna do WEBPUSHY

router.afterEach(() => {
    if (window.SR && window.SR.event) {
      window.SR.event.pageVisit()
        .then(function () {
          window.SR.dynamicContent.get();
      })
    }
})

export default router
