import {computed} from "vue";

export const usePagination = (paginationList) => {
  const page = computed(() => paginationList.value?.page)
  const totalByPage = computed(() => paginationList.value?.totalByPage)
  const totalItem = computed(() => paginationList.value?.totalItem)
  const items = computed(() => paginationList.value?.items)
  const itemsByPage = computed(() => paginationList.value?.items?.[page.value] ?? [])
  const fetchPagination = ({ params, items, fetch }, cleanable) => {
    if(!params.page) params.page = 1
    if(!params.limit) params.limit = 20
    if (!params.id) {
      return fetch(params).then((response) => {
        items.value = {
          items: cleanable ? { [params.page]: response?.items ?? [] } : { ...{ [params.page]: response?.items ?? [] }, ...(items.value?.items ?? []) },
          page: Number(params.page),
          totalByPage: Number(params.limit),
          totalItem: Number(response?.total_count ?? 0)
        }
        return Promise.resolve(response)
      })
    } else {
      return fetch(params).then((response) => {
        items.value[params.id] = {
            items: cleanable ? { [params.page]: response?.items ?? [] } : {
              ...{ [params.page]: response?.items ?? [] },
              ...(items.value?.[params.id]?.items ?? [])
            },
            page: Number(params.page),
            totalByPage: Number(params.limit),
            totalItem: Number(response?.total_count ?? 0)
          }
        return Promise.resolve(response)
      })
    }
  }

  return {
    page,
    totalByPage,
    totalItem,
    items,
    itemsByPage,
    fetchPagination
  }
}